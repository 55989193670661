.htmlTextContainer {
    text-align: left;
}

.htmlTextContainer ul {
    padding-left: 40px;
    list-style: none;
    text-align: left;
    margin-bottom: 1.5rem;
}

.htmlTextContainer ol {
    padding-left: 40px;
    list-style: none;
    text-align: left;
    margin-bottom: 1.5rem;
}

.htmlTextContainer ul ul {
    padding-left: 30px;
}
.htmlTextContainer ol ol {
    padding-left: 30px;
}
.htmlTextContainer ul ul ul {
    padding-left: 20px;
}
.htmlTextContainer ol ol ol {
    padding-left: 20px;
}
.htmlTextContainer ul li {
    position: relative;
    margin: 10px 0;
}
.htmlTextContainer ol li {
    position: relative;
    counter-reset: li;
    margin: 10px 0;
}
.htmlTextContainer ul li::after {
    position: absolute;
    top: 6px;
    left: -30px;
    content: '';
    width: 12px;
    height: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    background-color: #f58551;
}
.htmlTextContainer ol li::after {
    top: 0;
    left: -40px;
    width: 24px;
    height: 24px;
    counter-increment: li;
    content: counter(li);
    font-size: 16px;
    color: white;
    text-align: center;
    border-radius: 12px;
    
}

.htmlTextContainer ul li li::after {
    background-color: #f58551;
    border-radius: 0;
    border-radius: 12px;
    content: '';
    height: 2px;
    left: -30px;
    position: absolute;
    top: 11px;
    width: 10px;
}

.htmlTextContainer ul li li li::after {
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #f58551;
    content: '';
    height: 8px;
    left: -30px;
    position: absolute;
    top: 8px;
    width: 8px;
}